<template>
  <Title
    v-if="template"
    :title="title"
  />
</template>

<script>
import { mapState } from 'pinia';
import { useTemplatesStore } from '@/stores/index.js';
import Title from '@/components/title.vue';

export default {
  name: 'ProcessCreateTitle',
  components: {
    Title,
  },
  computed: {
    ...mapState(useTemplatesStore, ['template']),

    title() {
      const { name } = this.template;
      return `Create ${name} process`;
    },
  },
};
</script>
